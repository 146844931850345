header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #f5f5f5;
}

.site-title {
  letter-spacing: -2px;
}

ul {
  list-style-type: none;
  display: flex;

  li {
    padding: 10px;
  }
}
